<template>
  <div class="reply">
    <div class="top">医生回复</div>
    <div class="header">
      <van-field readonly
                 clickable
                 label="选择就诊人"
                 :value="patName"
                 @click="patNamePickerShow = true"
                 right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow"
                 round
                 position="bottom">
        <van-picker show-toolbar
                    :columns="patNameList"
                    @cancel="patNamePickerShow = false"
                    @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="list">
      <div v-for="(item,index) in replyList"
           :key="index"
           class="item">
        <van-image class="avatar"
                   width="60"
                   height="60"
                   src="https://xheryuanapp.xh2ph.com:8443/wxxcx/wx/guahao.png"
                   fit="contain" />
        <div class="content">
          <div>
            <span>问诊日期：</span>
            <span style="color:#1989fa;font-size: 15px;">{{item.date}}</span>
          </div>
          <div>
            <span>问诊科室：</span>
            <span style="color:#1989fa;font-size: 17px;">{{item.deptName}}</span>
          </div>
          <div>
            <span>问诊医生：</span>
            <span style="color:#1989fa">{{item.doctorName}}</span>
          </div>
          <div>
            <span>症状：</span>
            <span style="color:#1989fa">{{item.illness}}</span>
          </div>
          <div>
            <span>医生建议：</span>
            <span style="color:#1989fa">{{item.reply == '' ? "暂未回复" : item.reply}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      replyList: [],
    }
  },
  created () {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getMembers();
    }

  },
  methods: {
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent
        (local)
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取openId
    async login (code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getMembers();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getUserBindInfos",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].patCardNo + ')');
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getInterviewState()
      }
      else {
        this.$toast.fail("暂无建档，请先建档");
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getInterviewState()
    },
    // 获取医生回复
    async getInterviewState () {
      this.replyList = []
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/his/getInterviewState", {
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.patNameArray[this.patNameIndex].patCardNo
      })
      this.$toast.clear();
      if (res.code == 0) {
        this.replyList = res.data
      }
    }
  }
}
</script>

<style lang="less" >
.reply {
  .top {
    width: 100%;
    text-align: center;
    background-color: aquamarine;
    font-size: 32px;
    color: #cccccc;
    padding: 20px 0;
  }
  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    margin-top: 20px;
  }
  .header .van-cell {
    border-bottom: 1px solid rgb(201, 191, 191);
    padding: 30px;
  }
  .header .van-cell::after {
    border: 0;
  }
  .header .van-field__label {
    text-align: left;
    font-size: 40px;
  }
  .header .van-field__control {
    text-align: right;
    margin-right: -10px;
    font-size: 40px;
  }
  .header .van-icon {
    font-size: 40px;
  }
  .header .van-picker-column {
    font-size: 40px;
  }
  .header .van-picker__cancel,
  .header .van-picker__confirm {
    font-size: 40px;
    padding: 40px;
  }
  .list {
    padding-bottom: 60px;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 36px;
      padding: 20px 30px;
      padding-right: 60px;
    }
    .avatar {
      flex: 1;
    }
    .item {
      .content {
        flex: 4;
        margin-left: 20px;
        line-height: 70px;
        .van-icon {
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
